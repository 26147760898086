export const codesNAP = {
  '01.00': 'Cultures générales',
  '01.07': "Cultures générales associées à d'autres activités agricoles",
  '01.10': 'Production de légumes frais, de fleurs; pépinières',
  '01.16': 'Cultures fruitières',
  '01.17': 'Viticulture',
  '01.30': 'Vinification',
  '01.50': 'Elevage de bovins',
  '01.56': "Elevage de bovins associé à d'autres activités agricoles",
  '01.57': "Elevage d'ovins, caprins, équins",
  '01.60': 'Elevage de porcins',
  '01.63': 'Aviculture',
  '01.70': 'Productions agricoles diverses',
  '01.74': 'Chasse et piégeage',
  '01.80': 'Travaux agricoles à façon',
  '01.90': "Services effectués au profit de l'élevage",
  '02.10': 'Sylviculture et services aux forêts',
  '02.20': 'Exploitation forestière',
  '03.10': 'Pêche en mer',
  '03.20': 'Conchyliculture',
  '03.30': 'Pisciculture et pêche en eau douce',
  '03.40': 'Culture et récolte de produits marins divers',
  '35.01': 'Abattage du bétail',
  '35.04': 'Fabrication de charcuterie et de conserves de viandes',
  '35.05': 'Abattage de volaille',
  '36.10': 'Industrie laitière',
  '36.20': 'Fabrication de crèmes glacées, glaces et sorbets',
  '37.01': 'Conserverie de fruits et confitures',
  '37.02': 'Conserverie de légumes',
  '37.03': 'Conserverie de poissons',
  '37.04': 'Préparation de plats cuisinés',
  '38.10': 'Fabrication industrielle de pain et de pâtisserie fraîche',
  '38.40': 'Boulangerie, pâtisserie',
  '38.50': 'Pâtisserie',
  '39.01': 'Meunerie',
  '39.02': 'Biscuiterie, biscotterie',
  '39.03': 'Semoulerie',
  '39.04': 'Fabrication de pâtes alimentaires et de couscous',
  '39.05': 'Transformation de céréales secondaires',
  '39.06': 'Malterie',
  '39.07': 'Fabrication de produits amylacés',
  '39.08': "Fabrication d'aliments pour animaux",
  '40.11': "Fabrication d'huiles et corps gras bruts",
  '40.12': "Fabrication d'huiles et corps gras raffinés et de margarine",
  '40.21': 'Sucreries, raffineries de sucre',
  '40.31': 'Chocolaterie, confiserie',
  '40.32':
    "Torréfaction et brûlerie de café, thé, chicorée et infusions, préparation d'épices et herbes aromatiques",
  '40.33': 'Fabrication de condiments, vinaigre, sauces préparées',
  '40.34':
    "Fabrication d'aliments diététiques, aliments pour bébé, produits de régime",
  '40.35': "Fabrication d'entremets, desserts ménagers et petits déjeuners",
  '40.36': 'Fabrication de bouillons et potages',
  '40.37': 'Fabrication de produits alimentaires divers',
  '41.01': "Distillation d'alcool",
  '41.02': "Distillation d'eau de vie naturelle",
  '41.03':
    "Production de liqueurs et apéritifs alcoolisés autres qu'à base de vin",
  '41.04': "Production d'apéritifs à base de vin",
  '41.05': 'Champagnisation',
  '41.06': 'Brasserie',
  '41.07': 'Cidrerie',
  '41.08': 'Production de jus de fruits et de légumes',
  '41.09': 'Fabrication de boissons non alcoolisées élaborées',
  '41.10': "Production d'eaux minérales naturelles",
  '42.01': 'Transformation du tabac',
  '04.11': 'Production de combustibles minéraux solides',
  '04.21': 'Cokéfaction',
  '05.11': 'Extraction de pétrole',
  '05.21': 'Extraction de gaz naturel',
  '05.31': 'Raffinage de pétrole',
  '06.01': "Production et distribution d'électricité",
  '07.01': 'Distribution de gaz',
  '08.01': "Production et distribution d'eau",
  '08.02': "Chauffage urbain et distribution d'énergies diverses",
  '09.01': 'Extraction et préparation de minerai de fer',
  '10.01': 'Sidérurgie',
  '11.01': "Tréfilage de l'acier et production des dérivés du fil d'acier",
  '11.02': "Laminage à froid du feuillard d'acier",
  '11.03': 'Etirage et profilage des produits pleins en acier',
  '11.04': 'Profilage des produits plats en acier',
  '11.05': "Fabrication de tubes d'acier",
  '12.01': 'Extraction et préparation de bauxite',
  '12.02': 'Extraction et préparation de minerais de plomb, zinc et cuivre',
  '12.03': 'Extraction de minerais contenant des matières fissiles et fer',
  '12.04': "Extraction et préparation d'autres minerais métalliques",
  '13.01': "Métallurgie de l'aluminium et des autres métaux légers",
  '13.02': 'Métallurgie du plomb, du zinc, du cadmium',
  '13.03': 'Métallurgie des métaux précieux',
  '13.04': 'Métallurgie des ferro-alliages',
  '13.05': "Production d'autres métaux non ferreux",
  '13.10': 'Fabrication de demi-produits en aluminium et autres métaux légers',
  '13.11': 'Fabrication de demi-produits en plomb, zinc et cadmium',
  '13.12': 'Fabrication de demi-produits en cuivre',
  '13.13': 'Fabrication de demi-produits en métaux précieux',
  '13.14': "Fabrication d'autres demi-produits non ferreux",
  '13.15': 'Production et transformation de matières fissiles',
  '13.16': 'Production et transformation de matières fertiles',
  '14.01':
    'Production de sels (chlorure de sodium et sels divers) ; marais salants',
  '14.02': "Production de matériaux de carrière pour l'industrie",
  '14.03': 'Production de minéraux divers : asphalte, talc etc..',
  '15.01': "Extraction de sables et graviers d'alluvions",
  '15.02': 'Production de matériaux concassés de roche et de laitier',
  '15.03': 'Production de pierres de construction',
  '15.04': "Extraction d'argiles, kaolin, terres réfractaires",
  '15.05': 'Fabrication de plâtres et de produits en plâtre',
  '15.06': 'Fabrication de chaux et ciments',
  '15.07': "Préparation et livraison de béton prêt à l'emploi",
  '15.08': 'Fabrication de produits en béton',
  '15.09': 'Fabrication de matériaux de construction divers',
  '15.10': 'Fabrication de tuiles et briques',
  '15.11': 'Fabrication de produits réfractaires',
  '15.12':
    'Fabrication de produits en grès, en faïence, en autres matières céramiques',
  '15.13': 'Fabrication de vaisselle de ménage en céramique',
  '16.01': 'Fabrication façonnage et transformation de verre plat, miroiterie',
  '16.02':
    'Fabrication façonnage et transformation de verre creux mécanique, de verrerie de ménage',
  '16.03': 'Fabrication de verre à la main',
  '16.04': 'Fabrication de verre technique',
  '17.12': "Industrie de l'acide sulfurique et des produits dérivés",
  '17.13':
    'Fabrication de produits chimiques par électrolyse ou électrothermie',
  '17.14': 'Fabrication de gaz comprimés',
  '17.15':
    "Fabrication d'opacifiants minéraux compositions et couleurs pour émaux",
  '17.16': 'Fabrication de produits divers de la chimie minérale',
  '17.17': "Fabrication d'engrais azotés et autres produits azotés",
  '17.18': "Fabrication d'engrais phosphatés",
  '17.19': "Fabrication d'autres engrais",
  '17.21': 'Chimie organique de synthèse',
  '17.22': 'Fabrication de matières colorantes de synthèse',
  '17.23':
    'Traitement chimique des corps gras notamment stéarinerie et glycérinerie ; fabrication de produits de base pour détergents',
  '17.24': 'Fabrication de produits de base pour la pharmacie',
  '17.25':
    'Fabrication de produits auxiliaires pour les industries textiles, du cuir et du caoutchouc, transformation de matières animales et végétales',
  '17.26': 'Fabrication et distillation de goudrons',
  '17.27': 'Fabrication de matières plastiques',
  '17.28': 'Fabrication de caoutchouc synthétique et autres élastomères',
  '17.29':
    "Fabrication d'huiles essentielles, d'arômes naturels et synthétiques",
  '43.01': 'Production de fibres discontinues et de fils continus artificiels',
  '43.02': 'Production de fibres discontinues et de fils continus synthétiques',
  '20.01': 'Fonderie de métaux ferreux',
  '20.02': 'Fonderie de métaux non ferreux',
  '21.01': 'Forge, estampage, matriçage',
  '21.02': 'Découpage, emboutissage',
  '21.03': 'Traitement et revêtement des métaux',
  '21.04': 'Décolletage',
  '21.05': 'Boulonnerie, visserie',
  '21.06': 'Construction métallique',
  '21.07': 'Menuiserie métallique de bâtiment',
  '21.08': 'Mécanique générale, fabrication de moules et modèles',
  '21.09':
    "Fabrication d'outillage à main, d'outillage électroportatif, d'outillage agricole",
  '21.10': 'Fabrication de ressorts',
  '21.11': 'Fabrication de quincaillerie',
  '21.12': "Ferblanterie, fabrication d'articles de ménage, de coutellerie",
  '21.13': 'Fabrication de mobilier métallique',
  '21.14':
    'Fabrication de fûts et tonnelets métalliques, de boîtes et emballages métalliques fabrication de conditionnements métalliques',
  '21.15': 'Fabrication de petits articles métalliques',
  '21.16': "Frittage des métaux, fabrication d'aimants permanents",
  '21.17': "Fabrication d'armes de chasse, de tir, de défense",
  '50.01': 'Fabrication de pâtes à papier',
  '50.02': 'Fabrication de papiers et de cartons',
  '50.03': "Fabrication d'articles de papeterie",
  '50.04': 'Transformation du papier',
  '50.06': 'Fabrication de carton ondulé et de produits en carton ondulé',
  '50.07': 'Fabrication de cartonnages',
  '52.01': 'Fabrication de pneumatiques et chambres à air',
  '52.02': 'Rechapage et réparation industrielle de pneumatiques',
  '52.03': "Fabrication d'ouvrages en caoutchouc",
  '52.04': "Fabrication d'ouvrages en amiante",
  '53.01':
    'Fabrication de mélanges, plaques, feuilles, films, tubes, tuyaux et profilés',
  '53.02': "Fabrication de pièces diverses pour l'industrie",
  '53.03': "Fabrication d'emballages en matières plastiques",
  '53.04': "Fabrication d'éléments pour le bâtiment",
  '53.05': 'Fabrication de produits de consommation divers',
  '53.06': 'Fabrication de pellicules cellulosiques',
  '22.01': 'Fabrication de tracteurs agricoles',
  '22.02': "Fabrication d'autre matériel agricole",
  '23.01': 'Fabrication de machines-outils à métaux',
  '23.02': 'Fabrication de machines à bois',
  '23.03': "Fabrication d'outillage, outils pour machines",
  '23.04': "Fabrication d'engrenages et organes de transmission",
  '23.05': 'Fabrication de matériel de soudage',
  '24.01': 'Robinetterie',
  '24.02': 'Fabrication et installation de fours',
  '24.03':
    'Fabrication et installation de matériel aéraulique, thermique et frigorifique',
  '24.04':
    "Fabrication de moteurs à combustion interne autres que pour l'automobile et l'aéronautique",
  '24.05': 'Fabrication de transmissions hydrauliques et pneumatiques',
  '24.06': 'Fabrication de pompes et compresseurs',
  '24.07':
    "Fabrication de turbines thermiques et hydrauliques et d'équipements de barrages",
  '24.08': 'Chaudronnerie',
  '24.09':
    'Fabrication de machines pour les industries alimentaires chimiques, plastiques et de machines à chaussures',
  '24.10':
    'Fabrication de machines pour les industries textiles et de machines à coudre industrielles',
  '24.11':
    'Fabrication de machines pour les industries du papier, du carton et des arts graphiques',
  '25.01': 'Fabrication de matériel de travaux publics',
  '25.02':
    'Fabrication de matériel pour la sidérurgie pour la fonderie, pour la préparation des matériaux, matériel fixe de chemin de fer',
  '25.03': 'Fabrication de matériel de manutention et de levage',
  '25.04': 'Fabrication de matériel de mines et de forage',
  '34.01': 'Horlogerie',
  '34.02':
    "Fabrication d'appareils de pesage et de compteurs, d'instruments de métrologie",
  '34.03':
    'Fabrication de lunettes pour la correction et la protection de la vue',
  '34.04': "Fabrication d'instruments d'optique et de précision",
  '34.05': 'Fabrication de matériel photographique et cinématographique',
  '34.06': 'Fabrication de matériel médico-chirurgical et de prothèses',
  '34.07': 'Fabrication de roulements',
  '27.01': "Fabrication de matériel de traitement de l'information",
  '27.02': 'Fabrication de machines de bureau',
  '28.10':
    "Fabrication d'équipements de distribution, de commande à basse tension ; d'applications de l'électronique de puissance",
  '28.11':
    'Fabrication de matériel électrique de grande puissance ou à haute tension',
  '28.12':
    "Fabrication d'appareillage industriel à basse tension, de relais ; de matériel de signalisation",
  '28.13':
    'Fabrication de machines tournantes et transformateurs électriques de petite et moyenne puissance',
  '28.14': "Fabrication d'isolateurs et pièces isolantes en verre et céramique",
  '28.15':
    "Fabrication d'équipements d'automatisation de processus industriels",
  '28.16': 'Réparation de gros matériel électrique',
  '28.17': "Fabrication de matériel d'éclairage",
  '28.18': "Fabrication de fils et câbles isolés pour l'électricité",
  '28.19':
    "Fabrication et installation d'ascenseurs, monte-charge et escaliers mécaniques",
  '28.21': "Fabrication d'appareillage électrique d'installation",
  '28.22':
    "Fabrication de piles électriques et appareils d'éclairage à bas voltage",
  '28.23': "Fabrication d'accumulateurs",
  '28.24': 'Fabrication de lampes électriques',
  '29.11': 'Fabrication de matériel télégraphique et téléphonique',
  '29.12': "Fabrication d'appareils de radiologie et d'électronique médicale",
  '29.13':
    "Fabrication d'appareils de contrôle et de régulation spécifiquement conçus pour l'automatisme industriel, d'instruments et d'appareils électriques et électroniques de mesure",
  '29.14':
    'Fabrication de matériel professionnel électronique et radioélectrique',
  '29.15': 'Fabrication de composants passifs et de condensateurs fixes',
  '29.16': 'Fabrication de tubes électroniques et de semi-conducteurs',
  '29.21': "Fabrication d'appareils radiorécepteur et de téléviseurs",
  '29.22':
    "Fabrication d'appareils d'enregistrement et de reproduction du son et de l'image et de supports d'enregistrement",
  '30.01':
    "Fabrication d'appareils frigorifiques domestiques, de machines à laver le linge et à laver la vaisselle",
  '30.02':
    "Fabrication d'appareils ménagers de cuisine, de chauffage de l'eau et de chauffage de l'air non électriques",
  '30.03': "Fabrication d'autres appareils d'équipement ménager",
  '31.11': 'Construction de voitures particulières',
  '31.12': 'Construction de caravanes et remorques de tourisme',
  '31.13': 'Fabrication de pièces et équipements spécifiques pour automobiles',
  '31.14': 'Construction de véhicules utilitaires',
  '31.15':
    'Construction de carrosseries, bennes, remorques autres que de tourisme',
  '31.16': 'Fabrication de motocycles et cycles',
  '31.17': 'Fabrication de pièces et équipements pour cycles et motocycles',
  '31.21':
    "Fabrication et réparation de matériel ferroviaire roulant et d'autres matériels de transport guide",
  '26.01': 'Fabrication de véhicules blindés',
  '26.02': "Fabrication d'armes et munitions de guerre",
  '32.01': 'Construction de bâtiments de guerre',
  '32.02': 'Construction de navires de marine marchande',
  '32.03': "Construction d'autres bateaux",
  '32.04': "Fabrication et pose d'équipements spécifiques de bord",
  '32.05': 'Réparation de navires',
  '33.01': "Construction de cellules d'aéronefs",
  '33.02':
    "Fabrication de propulseurs d'aéronefs et d'équipements de propulseurs",
  '33.03': "Fabrication d'équipements spécifiques pour les aéronefs",
  '33.04': "Construction d'engins et de lanceurs spatiaux",
  '18.01': "Fabrication d'allumettes",
  '18.02': "Fabrication d'abrasifs appliqués",
  '18.03':
    "Fabrication d'explosifs, d'accessoires de mise à feu et d'artifices",
  '18.04': 'Fabrication de colles',
  '18.05': 'Fabrication de produits savonniers et de produits détergents',
  '18.06': "Fabrication de produits de ménage et de produits d'entretien",
  '18.07':
    "Fabrication de peintures, vernis, couleurs fines, encres d'imprimerie",
  '18.08': 'Fabrication de produits phytosanitaires',
  '18.09': 'Fabrication de produits photographiques et cinématographiques',
  '18.10':
    'Fabrication de charbons artificiels, de terres actives et de produits chimiques à usage métallurgique et mécanique',
  '18.11': 'Parfumerie',
  '19.01': 'Fabrication de spécialités pharmaceutiques',
  '19.02': "Fabrication d'autres produits pharmaceutiques",
  '44.10': 'Préparation et commerce de la laine, délainage',
  '44.11': "Préparation de lin, chanvre, et d'autres plantes textiles",
  '44.12': 'Filterie',
  '44.13': 'Filature de lin et de chanvre',
  '44.14': "Filature de l'industrie cotonnière",
  '44.15': "Filature de l'industrie lainière - cycle cardé",
  '44.16': "Filature de l'industrie lainière - cycle peigné",
  '44.17':
    'Moulinage et texturation de la soie et des textiles artificiels et synthétiques',
  '44.18': 'Teintures, apprêts et impressions',
  '44.20': "Fabrication d'étoffes à mailles",
  '44.21':
    'Fabrication de chandails, pull-over, polos, gilets etc... En bonneterie',
  '44.22': "Fabrication d'autres vêtements de dessus de bonneterie",
  '44.23': 'Fabrication de sous-vêtements de bonneterie',
  '44.24': "Fabrication d'articles chaussants de bonneterie",
  '44.25': "Fabrication d'autres articles de bonneterie",
  '44.30': 'Tissage des industries cotonnières et linières',
  '44.31': "Tissage de l'industrie lainière",
  '44.32': 'Tissage de soierie',
  '44.33': 'Industrie du jute',
  '44.34': 'Fabrication de tapis',
  '44.35': 'Fabrication de feutre',
  '44.36': "Fabrication d'étoffes non tissées ni tricotées",
  '44.37': "Enduction d'étoffes",
  '44.38': 'Fabrication de produits textiles élastiques',
  '44.39': 'Ficellerie, corderie, fabrication de filets',
  '44.40': 'Ouaterie',
  '44.41': 'Fabrication de dentelles, tulles, broderies et guipures',
  '44.42':
    'Fabrication de rubans, tresses, passementeries et articles textiles divers',
  '47.01': 'Confection de vêtements masculins',
  '47.02': 'Confection de vêtements féminins',
  '47.03': 'Confection de vêtements pour enfants',
  '47.04': 'Habillement sur mesure',
  '47.05': 'Confection de chemiserie et lingerie',
  '47.06': 'Confection de vêtements en matières plastiques',
  '47.07': 'Confection de corsets, gaines et soutien-gorges',
  '47.08': 'Confection de chapellerie pour hommes et femmes',
  '47.09': "Fabrication d'accessoires divers de l'habillement",
  '47.10': 'Fabrication de pelleterie et fourrures',
  '45.11': 'Tannerie, mégisserie',
  '45.21':
    "Fabrication d'articles de maroquinerie, d'articles de voyage et de chasse",
  '45.22': 'Fabrication de gants',
  '45.23': "Fabrication d'articles divers en cuir et similaires",
  '46.01': "Fabrication de chaussures et d'autres articles chaussants",
  '48.01': 'Scierie',
  '48.02': "Fabrication d'éléments de charpente et de menuiseries de bâtiment",
  '48.03': 'Fabrication de parquets, moulures et baguettes',
  '48.04':
    'Fabrication et transformation de panneaux, bois de placage, bois améliorés et traités',
  '48.05': "Fabrication d'emballages en bois",
  '48.06': 'Fabrication de bâtiments préfabriqués légers',
  '48.07': "Fabrication d'objets divers en bois",
  '49.01': 'Fabrication de meubles meublants',
  '49.02': 'Fabrication de sièges',
  '49.03': 'Fabrication de meubles de cuisine et meubles en bois blanc',
  '49.04': 'Fabrication de literie',
  '49.05':
    "Fabrication de meubles divers et industries connexes à l'industrie de l'ameublement",
  '49.06': 'Fabrication de mobilier fonctionnel non métallique',
  '54.01': "Fabrication de jeux, jouets et d'articles de puériculture",
  '54.02': "Fabrication d'articles de sport et de campement",
  '54.03': 'Fabrication de bateaux de plaisance',
  '54.04': 'Bijouterie, joaillerie',
  '54.05': "Fabrication d'instruments de musique",
  '54.06': "Fabrication d'articles de bureau et d'articles de paris",
  '54.07':
    "Fabrication d'accessoires d'ameublement en bronze et fer forgé, statuettes et articles funéraires",
  '54.08':
    "Fabrication d'articles de brosserie, d'articles de vannerie et d'articles en liège",
  '54.09': 'Laboratoires photographiques et cinématographiques',
  '54.10': "Fabrication d'articles divers non désignés ailleurs",
  '51.01': 'Agences de presse',
  '51.10': 'Imprimerie de labeur',
  '51.11': "Industries connexes à l'imprimerie",
  '51.12': 'Edition',
  '51.20': 'Presse',
  '51.30': 'Edition de disques, bandes et cassettes enregistrées',
  '55.10':
    "Travaux d'aménagement des terres et des eaux ; voirie ; parcs et jardins",
  '55.11': "Construction de lignes de transport d'électricité",
  '55.12': "Travaux d'infrastructure générale",
  '55.13': 'Construction de chaussées',
  '55.20': 'Entreprises de forages, sondages ; fondations spéciales',
  '55.30': "Construction d'ossatures autres que métalliques",
  '55.31': 'Installation industrielle, montage levage',
  '55.40': 'Installation électrique',
  '55.50': 'Construction industrialisée',
  '55.60': 'Maçonnerie et travaux courants de béton armé',
  '55.70': 'Génie climatique',
  '55.71': 'Menuiserie-serrurerie',
  '55.72': 'Couverture, plomberie, installations sanitaires',
  '55.73': 'Aménagements, finitions',
  '57.01': 'Commerce de gros de matières premières agricoles',
  '57.02': 'Commerce de gros de bestiaux',
  '57.03': 'Commerce de gros de fruits et légumes',
  '57.04': 'Commerce de gros de viandes sans abattage',
  '57.05': 'Commerce de gros de produits laitiers',
  '57.06': 'Commerce de gros de volailles et gibiers',
  '57.07': 'Commerce de gros de poissons',
  '57.08': 'Commerce de gros de vins, spiritueux, liqueurs',
  '57.09': "Commerce de gros d'autres boissons",
  '57.10': "Commerce de gros d'épicerie",
  '57.11': 'Commerces de gros spécialisés de produits alimentaires divers',
  '58.01':
    "Commerce de gros d'accessoires d'automobiles et de matériel de garage",
  '58.02': 'Commerce de gros de pneumatiques, cycles et motocycles',
  '58.03': "Commerce de gros de quincaillerie et d'appareils ménagers",
  '58.04': 'Commerce de gros de matériel électrique et électronique',
  '58.05': 'Commerce de gros des textiles',
  '58.06':
    "Commerce de gros de l'habillement, des chaussures et de la maroquinerie",
  '58.07': 'Commerce de gros de produits pharmaceutiques',
  '58.08': 'Commerce de gros de parfumerie, produits de beauté',
  '58.09': "Commerce de gros de droguerie, de produits d'entretien",
  '58.10': 'Commerce de gros de céramique et verrerie',
  '58.11': 'Commerce de gros de jouets, papeterie et articles de fumeurs',
  '58.12': 'Commerces de gros divers',
  '59.01': 'Commerce des textiles bruts',
  '59.02': 'Commerce de gros de cuirs et peaux',
  '59.03': 'Commerce de gros de charbon, minerais et minéraux',
  '59.04': 'Commerce de gros de produits pétroliers',
  '59.05': 'Commerce de métaux',
  '59.06': 'Commerce de produits chimiques industriels',
  '59.07': 'Commerce du bois',
  '59.08':
    "Commerce de matériaux de construction, de verre à vitres et d'appareils sanitaires",
  '59.09': 'Commerce de matériel agricole',
  '59.10': "Commerce d'équipements et de fournitures pour l'industrie",
  '59.11': 'Commerce de matériel et mobilier de bureau',
  '59.12': 'Commerce de matériel pour le bâtiment et les travaux publics',
  '59.13':
    'Commerce de fournitures diverses pour les utilisateurs professionnels du commerce et des services',
  '59.14': "Commerce de papiers et cartons en l'état",
  '60.01':
    'Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et demi produits',
  '60.02':
    "Intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques pour l'industrie",
  '60.03': 'Intermédiaires du commerce en bois et matériaux de construction',
  '60.04': 'Intermédiaires du commerce en machines, matériel et véhicules',
  '60.05':
    'Intermédiaires du commerce en meubles, articles de ménage et quincaillerie',
  '60.06':
    'Intermédiaires du commerce en textiles habillement, chaussures et articles en cuir',
  '60.07': 'Intermédiaires du commerce spécialisés en produits alimentaires',
  '60.08': "Centrales d'achats en produits alimentaires",
  '60.09':
    'Intermédiaires du commerce spécialisés en produits non désignés ailleurs',
  '60.10': 'Intermédiaires du commerce en produits divers (sans prédominance)',
  '60.11': "Centrales d'achats en produits non alimentaires",
  '61.01': 'Exploitation de super marchés',
  '61.02': 'Exploitation de magasins populaires',
  '61.03': "Exploitation d'hypermarchés",
  '62.11': "Commerce de détail indépendant d'alimentation générale",
  '62.12': 'Exploitation de supérettes indépendantes',
  '62.21':
    "Exploitation de magasins d'alimentation générale dépendant d'une entreprise à magasins multiples",
  '62.22':
    "Exploitation de supérettes dépendant d'une entreprise à magasins multiples",
  '62.30': "Coopératives alimentaires d'entreprise ou d'administration",
  '62.41': 'Commerce de détail de fruits et légumes',
  '62.42': 'Commerce de détail de produits laitiers',
  '62.43': 'Commerce de détail des viandes',
  '62.44': 'Commerce de détail de poissons et coquillages',
  '62.45': 'Commerce de détail de vins et boissons',
  '62.46':
    'Commerce de détail de confiserie et commerces de détail alimentaires divers',
  '63.01': 'Grands magasins',
  '63.02': 'Autres grandes surfaces non spécialisées non alimentaires',
  '63.03': 'Grandes surfaces semi spécialisées non alimentaires',
  '63.04': 'Entreprises de vente par correspondance à assortiment général',
  '63.05': 'Autres commerces non alimentaires non spécialisés',
  '64.11': "Commerce de détail de l'habillement",
  '64.12': 'Commerce de détail de la chaussure voyage',
  '64.13': 'Commerce de détail de la maroquinerie et des articles de voyage',
  '64.14': 'Commerce de détail des textiles pour la maison',
  '64.21': 'Commerce de détail du meuble',
  '64.22': "Commerce de détail de quincaillerie et d'appareils ménagers",
  '64.23': 'Commerce de détail de la droguerie, couleurs vernis',
  '64.24':
    'Commerce de détail de matériel électrique, radioélectrique et électroménager',
  '64.25': "Autres commerces de détail de l'équipement du foyer",
  '64.30': 'Pharmacies',
  '64.40': "Commerce de détail d'articles médicaux et de produits de beauté",
  '64.41':
    'Commerce de détail et réparation de motocycles, cycles et véhicules divers',
  '64.42': 'Commerce de détail des charbons et combustibles',
  '64.43': 'Commerce de détail de livres, papeterie et fournitures de bureau',
  '64.44':
    "Commerce de détail d'optique (médicale et non médicale) et de photographie",
  '64.45': "Commerce de détail de l'horlogerie-bijouterie",
  '64.46': "Commerce de détail de fleurs, graines et petits animaux d'agrément",
  '64.47': "Commerce de détail d'articles de sport et de camping",
  '64.48': 'Commerce de détail du tabac',
  '64.49': 'Commerces de détail divers',
  '68.01': 'Transports ferroviaires',
  '69.11': 'Transports routiers de marchandises en zone longue',
  '69.12': 'Camionnage et transport routier de marchandises en zone courte',
  '69.21': 'Transports urbains de voyageurs',
  '69.22': 'Transports routiers de voyageurs',
  '69.23': 'Taxis',
  '69.24': 'Déménagement',
  '69.25': 'Location de véhicules industriels',
  '69.26': 'Transport par conduite',
  '70.01': 'Transports fluviaux de passagers',
  '70.02': 'Transports fluviaux de marchandises',
  '71.01': 'Transports maritimes autres que de produits pétroliers',
  '71.02': 'Transports maritimes de produits pétroliers',
  '71.03': "Navigation côtière et d'estuaire",
  '72.01': 'Transports aériens',
  '73.01': 'Gares routières',
  '73.02': 'Ports fluviaux et voies fluviales',
  '73.03': 'Ports maritimes',
  '73.04': 'Aéroports',
  '73.05': "Exploitation d'ouvrages routiers à péage",
  '73.06': 'Exploitation de parkings',
  '73.07': 'Entrepôts autres que frigorifiques',
  '73.08': 'Entrepôts frigorifiques',
  '73.09': 'Remorquage et pilotage',
  '74.01': 'Collecte de fret maritime',
  '74.02': 'Collecte de fret aérien',
  '74.03': 'Collecte de fret terrestre et fluvial',
  '74.04': 'Manutention portuaire',
  '74.05': 'Manutention terrestre et fluviale',
  '74.06': "Activités spécifiques d'auxiliaires des transports maritimes",
  '74.07': "Activités spécifiques d'auxiliaires de transport aérien",
  '74.08': 'Autres auxiliaires des transports',
  '74.09': 'Agences de voyages',
  '74.10': 'Routage',
  '75.01': 'P.T.T. - services communs et recherche',
  '75.02': 'P.T.T. - services de télécommunications',
  '75.03': 'P.T.T. - services postaux et financiers',
  '75.04': 'Activités de télécommunications hors p.t.t.',
  '65.01': "Commerce de détail d'accessoires et d'équipements automobiles",
  '65.02': 'Commerce de détail de carburants et lubrifiants',
  '65.03': 'Vente et réparation de véhicules automobiles',
  '65.06': 'Réparation de véhicules automobiles',
  '67.01': 'Restaurants et cafés-restaurants (sans hébergement)',
  '67.02': 'Cantines',
  '67.03': 'Traiteurs',
  '67.04': 'Débits de boissons (sans spectacle)',
  '67.05': 'Cafés-tabacs',
  '67.06': 'Débits de boissons avec spectacle',
  '67.07': 'Cafés associés à une autre activité',
  '67.08': 'Hôtels avec restaurant',
  '67.09': 'Hôtels sans restaurant',
  '67.10': 'Wagons-lits et wagons-restaurants',
  '67.11':
    "Foyers d'étudiants et de jeunes travailleurs, résidences universitaires",
  '67.12': "Installations d'hébergement à équipements développés",
  '67.13': "Installations d'hébergement à équipements légers",
  '56.01': 'Récupération de ferraille et vieux métaux non ferreux',
  '56.02': 'Récupération de produits divers',
  '76.00': 'Holdings',
  '77.01': "Cabinets d'études techniques",
  '77.02': "Cabinets d'études économiques et sociologiques",
  '77.03': "Cabinets d'études informatiques et d'organisation",
  '77.04': 'Travaux à façon informatiques',
  '77.05': "Cabinets d'architecte",
  '77.06': 'Cabinets de métreur et de géomètre',
  '77.07': 'Cabinets de conseils en information et documentation',
  '77.08': 'Cabinets juridiques et offices publics et ministériels',
  '77.09': 'Cabinets d expertise comptable et d analyse financière',
  '77.10': 'Créateurs et intermédiaires en publicité',
  '77.11': 'Régies publicitaires',
  '77.12': 'Travaux à façon divers',
  '77.13': 'Prestation temporaire de personnel',
  '77.14': 'Services divers rendus principalement aux entreprises',
  '77.15': 'Ordres et syndicats professionnels',
  '78.01': 'Auxiliaires financiers',
  '78.02': "Auxiliaires d'assurances",
  '79.01': 'Promotion immobilière',
  '79.02': 'Promotion en infrastructure (services marchands)',
  '79.03': 'Supports juridiques de programmes',
  '79.04': "Administration d'immeubles",
  '79.05': "Administration d'infrastructures",
  '79.06': 'Agences immobilières',
  '80.01': 'Location de machines et équipements agricoles',
  '80.02': 'Location de matériel pour le bâtiment et les travaux publics',
  '80.03': 'Location de matériel de bureau',
  '80.04': 'Location de voitures particulières',
  '80.05': 'Location de wagons',
  '80.06': 'Location de biens de consommation',
  '80.07': 'Crédit-bail mobilier',
  '80.08': "Location d'autres biens d'équipement",
  '82.01': 'Enseignement général primaire et secondaire (services marchands)',
  '82.02': 'Formation des adultes et formation continue (services marchands)',
  '82.03':
    'Autres enseignements spécialisés et professionnels et enseignement supérieur (services marchands)',
  '82.04': 'Enseignement par correspondance (services marchands)',
  '82.05': 'Professeurs indépendants',
  '82.06': 'Auto-écoles, écoles de pilotage',
  '83.01': 'Recherche scientifique et technique (services marchands)',
  '66.01': "Réparation de chaussures et d'autres articles en cuir",
  '66.02': "Réparation d'appareils électriques pour le ménage",
  '66.03': 'Réparation de montres et horloges de bijouterie',
  '66.04': 'Réparations non désignées ailleurs et sans spécialisation',
  '84.01': 'Médecine systématique et de dépistage (services marchands)',
  '84.02': 'Etablissements hospitaliers généraux',
  '84.03': 'Cliniques générales',
  '84.04': 'Etablissements hospitaliers spécialisés',
  '84.05': 'Cliniques spécialisées',
  '84.06': 'Dispensaires',
  '84.07': 'Autres instituts pour la santé',
  '84.08': "Laboratoires d'analyses médicales extra-hospitaliers",
  '84.09': "Centres de transfusion sanguine et banques d'organes",
  '84.10': 'Cabinets de médecin',
  '84.11': 'Cabinets dentaires',
  '84.12': "Cabinets d'auxiliaires médicaux",
  '84.13': 'Ambulances',
  '84.14': 'Vétérinaires, cliniques vétérinaires',
  '85.01': 'Crèches',
  '85.02':
    "Etablissements pour l'enfance protégée, handicapée ou inadaptée (services marchands)",
  '85.03':
    'Etablissements pour adultes nécessiteux, handicapés, ou inadaptés (services marchands)',
  '85.04': 'Etablissements pour personnes âgées',
  '86.01': 'Radiodiffusion et télévision',
  '86.02': 'Production de films',
  '86.03': 'Distribution de films',
  '86.04': 'Gestion de salles de cinéma',
  '86.05': 'Gestion de salles de spectacle (services marchands)',
  '86.06': 'Autres spectacles et services récréatifs (services marchands)',
  '86.07':
    'Création et interprétation littéraires et artistiques (services marchands)',
  '86.08': 'Services annexes aux spectacles',
  '86.09': "Jeux de hasard et d'argent",
  '86.10': "Gestion d'équipements et centres sportifs (services marchands)",
  '86.11': 'Remontées mécaniques',
  '86.12': 'Professeurs de sport et sportifs professionnels',
  '87.01': 'Laverie-blanchisserie-teinturerie de détail',
  '87.02': 'Blanchisserie et teinturerie de gros',
  '87.03': 'Salons de coiffure',
  '87.04': 'Esthétique corporelle',
  '87.05': 'Services funéraires',
  '87.06': 'Studios de photographie',
  '87.07': 'Services personnels divers',
  '87.08': 'Services de nettoyage',
  '87.09': 'Enlèvement des ordures (services marchands)',
  '87.10': "Autres services d'assainissement (services marchands)",
  '81.11': 'Location de logements',
  '81.21': "Location d'immeubles à usages industriels et commerciaux",
  '81.22': 'Crédit bail immobilier',
  '88.01': 'Assurances "vie et capitalisation"',
  '88.02': 'Assurances "i.a.r.d."',
  '88.03': 'Réassurance',
  '88.04': 'Assurances relevant du code de la mutualité',
  '88.05': 'Caisses de retraites relevant de la législation sur les assurances',
  '89.01': "Instituts d'émission",
  '89.02': "Banques de dépôts, d'affaires, de crédit à long et moyen terme",
  '89.03': 'Crédit coopératif et mutuel',
  '89.04': 'Etablissements financiers et unions économiques',
  '89.05': 'Organismes financiers à statut légal spécial',
  '89.06': "Organismes de répartition d'emprunts collectifs obligataires",
  '89.07': 'Sociétés de placements en valeurs mobilières',
  '90.01': 'Pouvoirs publics',
  '90.02': 'Administration centrale',
  '90.03': 'Services extérieurs des administrations économiques et financières',
  '90.04': 'Services extérieurs des autres administrations',
  '90.05': 'Activités administratives spécialisées diverses',
  '90.11': 'Administration locale',
  '90.12':
    'Promotion en infrastructure (services non marchands à caractère public)',
  '90.13':
    'Voirie et assainissement (services non marchand à caractère public)',
  '90.21': 'Cours et tribunaux',
  '90.22': 'Etablissements pénitentiaires',
  '90.23': "Etablissements d'éducation surveillée",
  '90.24': 'Police nationale',
  '90.25': 'Protection civile',
  '90.26': 'Gendarmerie',
  '90.31': 'Commandements territoriaux militaires',
  '90.32': 'Forces et infrastructures des forces',
  '90.33': "Dépôts, magasins et organismes d'approvisionnement militaire",
  '90.34': 'Ecoles militaires',
  '90.35': 'Service de santé militaire',
  '90.36': "Laboratoires et centres d'essais militaires",
  '91.01': 'Organismes généraux de la sécurité sociale',
  '91.02': 'Sécurité sociale : maladie, maternité, accidents de travail',
  '91.03': 'Sécurité sociale : vieillesse',
  '91.04':
    'Caisses de retraites ne relevant pas de la législation sur les assurances',
  '91.05': 'Sécurité sociale : allocations familiales',
  '91.06':
    'Couverture du risque chômage et autres garanties du maintien du revenu',
  '92.11':
    'Enseignement préscolaire (services non marchands à caractère public)',
  '92.12':
    'Enseignement élémentaire (services non marchands à caractère public)',
  '92.13':
    'Enseignement du second degré premier cycle (services non marchands à caractère public)',
  '92.14':
    'Enseignement du second degré second cycle (services non marchands à caractère public)',
  '92.15': 'Enseignement supérieur (services non marchands à caractère public)',
  '92.16':
    'Enseignement artistique (services non marchands à caractère public)',
  '92.17':
    'Formation de fonctionnaires (services non marchands à caractère public)',
  '92.18':
    'Formation des adultes et formation continue (services non marchands à caractère public)',
  '92.21': 'Enseignement.(services non marchands à caractère privé)',
  '93.11':
    'Recherche scientifique et technique (services non marchands à caractère public)',
  '93.21':
    'Recherche scientifique et technique (services non marchands à caractère privé))',
  '94.11':
    'Médecine systématique et de dépistage (services non marchands à caractère public)',
  '94.21':
    'Médecine systématique et de dépistage (services non marchands à caractère privé))',
  '95.11':
    "Organisme locaux d'action sociale (services non marchands à caractère public)",
  '95.12':
    "Etablissements pour l'enfance protégée, handicapée, ou inadaptée (services non marchands à caractère public)",
  '95.13':
    'Etablissements pour adultes nécessiteux, handicapés, ou inadaptés (services non marchands à caractère public)',
  '95.21':
    'Oeuvres et organismes sociaux à compétence générale (services non marchands à caractère privé)',
  '95.22':
    'Oeuvres et organismes sociaux spécialisés (services non marchands à caractère privé)',
  '95.23':
    "Etablissements pour l'enfance protégée, handicapée, inadaptée (services non marchands à caractère privé)",
  '95.24':
    'Etablissements pour adultes nécessiteux, handicapés ou inadaptés (services non marchands à caractère privé)',
  '96.11':
    'Gestion de bibliothèques (services non marchands à caractère public)',
  '96.12':
    'Conservation du patrimoine culturel (services non marchands à caractère public)',
  '96.13':
    'Gestion de salles de théâtre (services non marchands à caractère public)',
  '96.14':
    "Gestion d'équipements culturels (services non marchands à caractère public)",
  '96.15':
    "Gestion d'équipements socio-éducatifs (services non marchands à caractère public)",
  '96.16':
    'Associations culturelles et socio-éducatives (services non marchands à caractère public)',
  '96.17':
    "Gestion d'équipements sportifs (services non marchands à caractère public)",
  '96.18': 'Associations sportives (services non marchands à caractère public)',
  '96.21':
    'Gestion de bibliothèques et conservation du patrimoine culturel (services non marchands à caractère privé)',
  '96.22':
    "Gestion d'équipements culturels, socio-éducatifs et d'autres salles de spectacle (services non marchands à caractère privé)",
  '96.23':
    'Associations culturelles et socio-éducatives (services non marchands à caractère privé)',
  '96.24':
    "Gestion d'équipements sportifs (services non marchands à caractère privé)",
  '96.25': 'Associations sportives (services non marchands à caractère privé)',
  '97.11':
    'Organisations économiques (services non marchands à caractère public)',
  '97.12': 'Offices du tourisme (services non marchands à caractère public)',
  '97.21':
    'Organisations syndicales (services non marchands à caractère prive)',
  '97.22':
    'Organisations religieuses et philosophiques (services non marchands à caractère privé)',
  '97.23':
    'Autres services fournis à la collectivité (services non marchands à caractère privé)',
  '98.01': 'Services domestiques',
  '99.01':
    'Représentation diplomatique étrangère en France ; organismes internationaux',
};
