export enum EAdministration {
  EDUCATION_NATIONALE = 'education-nationale',
  AGENCE_BIO = 'agence-bio',
  ADEME = 'ademe',
  DILA = 'dila',
  DINUM = 'dinum',
  MC = 'mc',
  MEF = 'mef',
  INPI = 'inpi',
  INSEE = 'insee',
  MTPEI = 'mtpei',
  MI = 'mi',
  VIES = 'vies',
  DJEPVA = 'djepva',
  ESSFRANCE = 'ess-france',
  MARCHE_INCLUSION = 'marche-inclusion',
  INFOGREFFE = 'infogreffe',
  DOUANES = 'douanes',
  DGFIP = 'dgfip',
  URSSAF = 'urssaf',
  MSA = 'msa',
  FNTP = 'fntp',
  QUALIFELEC = 'qualifelec',
  QUALIBAT = 'qualibat',
  OPQIBI = 'opqibi',
}
