export const codesNAF1993 = {
  '01.1A': 'Culture de céréales ; cultures industrielles',
  '01.1C': 'Culture de légumes ; maraîchage',
  '01.1D': 'Horticulture ; pépinières',
  '01.1F': 'Culture fruitière',
  '01.1G': 'Viticulture',
  '01.2A': 'Elevage de bovins',
  '01.2C': "Elevage d'ovins, caprins et équidés",
  '01.2E': 'Elevage de porcins',
  '01.2G': 'Elevage de volailles',
  '01.2J': "Elevage d'autres animaux",
  '01.3Z': 'Culture et élevage associés',
  '01.4A': 'Services aux cultures productives',
  '01.4B': 'Réalisation et entretien de plantations ornementales',
  '01.4D': "Services annexes à l'élevage",
  '01.5Z': 'Chasse',
  '02.0A': 'Sylviculture',
  '02.0B': 'Exploitation forestière',
  '02.0D': 'Services forestiers',
  '05.0A': 'Pêche',
  '05.0C': 'Pisciculture, aquaculture',
  '10.1Z': 'Extraction et agglomération de la houille',
  '10.2Z': 'Extraction et agglomération du lignite',
  '10.3Z': 'Extraction et agglomération de la tourbe',
  '11.1Z': "Extraction d'hydrocarbures",
  '11.2Z': "Services annexes à l'extraction d'hydrocarbures",
  '12.0Z': "Extraction de minerais d'uranium",
  '13.1Z': 'Extraction de minerais de fer',
  '13.2Z': 'Extraction de minerais de métaux non ferreux',
  '14.1A': 'Extraction de pierres pour la construction',
  '14.1C': 'Extraction de calcaire industriel, de gypse et de craie',
  '14.1E': "Extraction d'ardoise",
  '14.2A': 'Production de sables et de granulats',
  '14.2C': "Extraction d'argiles et de kaolin",
  '14.3Z':
    "Extraction de minéraux pour l'industrie chimique et d'engrais naturels",
  '14.4Z': 'Production de sel',
  '14.5Z': 'Activités extractives n.c.a.',
  '15.1A': 'Production de viandes de boucherie',
  '15.1C': 'Production de viandes de volailles',
  '15.1E': 'Préparation industrielle de produits à base de viandes',
  '15.1F': 'Charcuterie',
  '15.2Z': 'Industrie du poisson',
  '15.3A': 'Transformation et conservation de pommes de terre',
  '15.3C': 'Préparation de jus de fruits et légumes',
  '15.3E': 'Transformation et conservation de légumes',
  '15.3F': 'Transformation et conservation de fruits',
  '15.4A': "Fabrication d'huiles et graisses brutes",
  '15.4C': "Fabrication d'huiles et graisses raffinées",
  '15.4E': 'Fabrication de margarine',
  '15.5A': 'Fabrication de lait liquide et de produits frais',
  '15.5B': 'Fabrication de beurre',
  '15.5C': 'Fabrication de fromages',
  '15.5D': "Fabrication d'autres produits laitiers",
  '15.5F': 'Fabrication de glaces et sorbets',
  '15.6A': 'Meunerie',
  '15.6B': 'Autres activités de travail des grains',
  '15.6D': 'Fabrication de produits amylacés',
  '15.7A': "Fabrication d'aliments pour animaux de ferme",
  '15.7C': "Fabrication d'aliments pour animaux de compagnie",
  '15.8A': 'Fabrication industrielle de pain et de pâtisserie fraîche',
  '15.8B': 'Cuisson de produits de boulangerie',
  '15.8C': 'Boulangerie et boulangerie-pâtisserie',
  '15.8D': 'Pâtisserie',
  '15.8F': 'Biscotterie, biscuiterie, pâtisserie de conservation',
  '15.8H': 'Fabrication de sucre',
  '15.8K': 'Chocolaterie, confiserie',
  '15.8M': 'Fabrication de pâtes alimentaires',
  '15.8P': 'Transformation du thé et du café',
  '15.8R': 'Fabrication de condiments et assaisonnements',
  '15.8T': "Fabrication d'aliments adaptés à l'enfant et diététiques",
  '15.8V': 'Industries alimentaires n.c.a.',
  '15.9A': "Production d'eaux de vie naturelles",
  '15.9B': 'Fabrication de spiritueux',
  '15.9D': "Production d'alcool éthylique de fermentation",
  '15.9F': 'Champagnisation',
  '15.9G': 'Vinification',
  '15.9J': 'Cidrerie',
  '15.9L': "Production d'autres boissons fermentées",
  '15.9N': 'Brasserie',
  '15.9Q': 'Malterie',
  '15.9S': 'Industrie des eaux de table',
  '15.9T': 'Production de boissons rafraîchissantes',
  '16.0Z': 'Industrie du tabac',
  '17.1A': "Filature de l'industrie cotonnière",
  '17.1C': "Filature de l'industrie lainière - cycle cardé",
  '17.1E': 'Préparation de la laine',
  '17.1F': "Filature de l'industrie lainière - cycle peigné",
  '17.1H': 'Préparation et filature du lin',
  '17.1K':
    'Moulinage et texturation de la soie et des textiles artificiels ou synthétiques',
  '17.1M': 'Fabrication de fils à coudre',
  '17.1P': "Préparation et filature d'autres fibres",
  '17.2A': "Tissage de l'industrie cotonnière",
  '17.2C': "Tissage de l'industrie lainière - cycle cardé",
  '17.2E': "Tissage de l'industrie lainière - cycle peigné",
  '17.2G': 'Tissage de soieries',
  '17.2J': "Tissage d'autres textiles",
  '17.3Z': 'Ennoblissement textile',
  '17.4A': "Fabrication de linge de maison et d'articles d'ameublement",
  '17.4B': 'Fabrication de petits articles textiles de literie',
  '17.4C': "Fabrication d'autres articles confectionnés en textile",
  '17.5A': 'Fabrication de tapis et moquettes',
  '17.5C': 'Ficellerie, corderie, fabrication de filets',
  '17.5E': 'Fabrication de non-tissés',
  '17.5G': 'Industries textiles n.c.a.',
  '17.6Z': "Fabrication d'étoffes à maille",
  '17.7A': 'Fabrication de bas et chaussettes',
  '17.7C': 'Fabrication de pull-overs et articles similaires',
  '18.1Z': 'Fabrication de vêtements en cuir',
  '18.2A': 'Fabrication de vêtements de travail',
  '18.2C': 'Fabrication de vêtements sur mesure',
  '18.2D': 'Fabrication de vêtements de dessus pour hommes et garçonnets',
  '18.2E': 'Fabrication de vêtements de dessus pour femmes et fillettes',
  '18.2G': 'Fabrication de vêtements de dessous',
  '18.2J': "Fabrication d'autres vêtements et accessoires",
  '18.3Z': 'Industrie des fourrures',
  '19.1Z': 'Apprêt et tannage des cuirs',
  '19.2Z': "Fabrication d'articles de voyage et de maroquinerie",
  '19.3Z': 'Fabrication de chaussures',
  '20.1A': 'Sciage et rabotage du bois',
  '20.1B': 'Imprégnation du bois',
  '20.2Z': 'Fabrication de panneaux de bois',
  '20.3Z': 'Fabrication de charpentes et de menuiseries',
  '20.4Z': "Fabrication d'emballages en bois",
  '20.5A': "Fabrication d'objets divers en bois",
  '20.5C': "Fabrication d'objets en liège, vannerie ou sparterie",
  '21.1A': 'Fabrication de pâte à papier',
  '21.1C': 'Fabrication de papier et de carton',
  '21.2A': 'Industrie du carton ondulé',
  '21.2B': 'Fabrication de cartonnages',
  '21.2C': "Fabrication d'emballages en papier",
  '21.2E': "Fabrication d'articles en papier à usage sanitaire ou domestique",
  '21.2G': "Fabrication d'articles de papeterie",
  '21.2J': 'Fabrication de papiers peints',
  '21.2L': "Fabrication d'autres articles en papier ou en carton",
  '22.1A': 'Edition de livres',
  '22.1C': 'Edition de journaux',
  '22.1E': 'Edition de revues et périodiques',
  '22.1G': "Edition d'enregistrements sonores",
  '22.1J': "Autres activités d'édition",
  '22.2A': 'Imprimerie de journaux',
  '22.2C': 'Autre imprimerie (labeur)',
  '22.2E': 'Reliure et finition',
  '22.2G': 'Composition et photogravure',
  '22.2J': 'Autres activités graphiques',
  '22.3A': "Reproduction d'enregistrements sonores",
  '22.3C': "Reproduction d'enregistrements vidéo",
  '22.3E': "Reproduction d'enregistrements informatiques",
  '23.1Z': 'Cokéfaction',
  '23.2Z': 'Raffinage de pétrole',
  '23.3Z': 'Elaboration et transformation de matières nucléaires',
  '24.1A': 'Fabrication de gaz industriels',
  '24.1C': 'Fabrication de colorants et de pigments',
  '24.1E': "Fabrication d'autres produits chimiques inorganiques de base",
  '24.1G': "Fabrication d'autres produits chimiques organiques de base",
  '24.1J': "Fabrication de produits azotés et d'engrais",
  '24.1L': 'Fabrication de matières plastiques de base',
  '24.1N': 'Fabrication de caoutchouc synthétique',
  '24.2Z': 'Fabrication de produits agrochimiques',
  '24.3Z': 'Fabrication de peintures et vernis',
  '24.4A': 'Fabrication de produits pharmaceutiques de base',
  '24.4C': 'Fabrication de médicaments',
  '24.4D': "Fabrication d'autres produits pharmaceutiques",
  '24.5A': "Fabrication de savons, détergents et produits d'entretien",
  '24.5C': 'Fabrication de parfums et de produits pour la toilette',
  '24.6A': 'Fabrication de produits explosifs',
  '24.6C': 'Fabrication de colles et gélatines',
  '24.6E': "Fabrication d'huiles essentielles",
  '24.6G': 'Fabrication de produits chimiques pour la photographie',
  '24.6J': 'Fabrication de supports de données',
  '24.6L': 'Fabrication de produits chimiques à usage industriel',
  '24.7Z': 'Fabrication de fibres artificielles ou synthétiques',
  '25.1A': 'Fabrication de pneumatiques',
  '25.1C': 'Rechapage de pneumatiques',
  '25.1E': "Fabrication d'autres articles en caoutchouc",
  '25.2A':
    'Fabrication de plaques, feuilles, tubes et profilés en matières plastiques',
  '25.2C': "Fabrication d'emballages en matières plastiques",
  '25.2E': "Fabrication d'éléments en matières plastiques pour la construction",
  '25.2G': "Fabrication d'articles divers en matières plastiques",
  '25.2H': 'Fabrication de pièces techniques en matières plastiques',
  '26.1A': 'Fabrication de verre plat',
  '26.1C': 'Façonnage et transformation du verre plat',
  '26.1E': 'Fabrication de verre creux',
  '26.1G': 'Fabrication de fibres de verre',
  '26.1J': "Fabrication et façonnage d'articles techniques en verre",
  '26.1K': "Fabrication d'isolateurs en verre",
  '26.2A': "Fabrication d'articles céramiques à usage domestique ou ornemental",
  '26.2C': "Fabrication d'appareils sanitaires en céramique",
  '26.2E': "Fabrication d'isolateurs et pièces isolantes en céramique",
  '26.2G': "Fabrication d'autres produits céramiques à usage technique",
  '26.2J': "Fabrication d'autres produits céramiques",
  '26.2L': 'Fabrication de produits céramiques réfractaires',
  '26.3Z': 'Fabrication de carreaux en céramique',
  '26.4A': 'Fabrication de briques',
  '26.4B': 'Fabrication de tuiles',
  '26.4C': 'Fabrication de produits divers en terre cuite',
  '26.5A': 'Fabrication de ciment',
  '26.5C': 'Fabrication de chaux',
  '26.5E': 'Fabrication de plâtre',
  '26.6A': "Fabrication d'éléments en béton pour la construction",
  '26.6C': "Fabrication d'éléments en plâtre pour la construction",
  '26.6E': "Fabrication de béton prêt à l'emploi",
  '26.6G': 'Fabrication de mortiers et bétons secs',
  '26.6J': "Fabrication d'ouvrages en fibre-ciment",
  '26.6L': "Fabrication d'autres ouvrages en béton ou en plâtre",
  '26.7Z': 'Travail de la pierre',
  '26.8A': 'Fabrication de produits abrasifs',
  '26.8C': 'Fabrication de produits minéraux non métalliques n.c.a.',
  '27.1Z': 'Sidérurgie (CECA)',
  '27.2A': 'Fabrication de tubes en fonte',
  '27.2C': 'Fabrication de tubes en acier',
  '27.3A': 'Etirage à froid',
  '27.3C': 'Laminage à froid de feuillards',
  '27.3E': 'Profilage à froid par formage ou pliage',
  '27.3G': 'Tréfilage à froid',
  '27.3J': 'Production de ferroalliages et autres produits non CECA',
  '27.4A': 'Production de métaux précieux',
  '27.4C': "Production d'aluminium",
  '27.4D': "Première transformation de l'aluminium",
  '27.4F': "Production de plomb, de zinc ou d'étain",
  '27.4G': "Première transformation du plomb, du zinc ou de l'étain",
  '27.4J': 'Production de cuivre',
  '27.4K': 'Première transformation du cuivre',
  '27.4M': 'Métallurgie des autres métaux non ferreux',
  '27.5A': 'Fonderie de fonte',
  '27.5C': "Fonderie d'acier",
  '27.5E': 'Fonderie de métaux légers',
  '27.5G': "Fonderie d'autres métaux non ferreux",
  '28.1A': 'Fabrication de constructions métalliques',
  '28.1C': 'Fabrication de menuiseries et fermetures métalliques',
  '28.2A': 'Fabrication de réservoirs et citernes métalliques',
  '28.2B': 'Fabrication de bouteilles pour gaz comprimés',
  '28.2D': 'Fabric. radiateurs et chaudières pour chauffage central',
  '28.3A': 'Fabrication de générateurs de vapeur',
  '28.3B': 'Chaudronnerie nucléaire',
  '28.3C': 'Chaudronnerie-tuyauterie',
  '28.4A': 'Forge, estampage, matriçage',
  '28.4B': 'Découpage, emboutissage',
  '28.4C': 'Métallurgie des poudres',
  '28.5A': 'Traitement et revêtement des métaux',
  '28.5C': 'Décolletage',
  '28.5D': 'Mécanique générale',
  '28.6A': 'Fabrication de coutellerie',
  '28.6C': "Fabrication d'outillage à main",
  '28.6D': "Fabrication d'outillage mécanique",
  '28.6F': 'Fabrication de serrures et de ferrures',
  '28.7A': 'Fabrication de fûts et emballages métalliques similaires',
  '28.7C': "Fabrication d'emballages métalliques légers",
  '28.7E': "Fabrication d'articles en fils métalliques",
  '28.7G': 'Visserie et boulonnerie',
  '28.7H': 'Fabrication de ressorts',
  '28.7J': 'Fabrication de chaînes',
  '28.7L': "Fabrication d'articles métalliques ménagers",
  '28.7M': 'Fabrication de coffres-forts',
  '28.7N': 'Fabrication de petits articles métalliques',
  '28.7P': "Fabrication d'articles métalliques n.c.a.",
  '29.1A': 'Fabrication de moteurs et turbines',
  '29.1C': 'Fabrication de pompes et compresseurs',
  '29.1D': 'Fabrication de transmissions hydrauliques et pneumatiques',
  '29.1F': "Fabrication d'articles de robinetterie",
  '29.1H': 'Fabrication de roulements',
  '29.1J': "Fabrication d'organes mécaniques de transmission",
  '29.2A': 'Fabrication de fours et brûleurs',
  '29.2C': 'Fabric. ascenseurs, monte-charges et escaliers mécaniques',
  '29.2D': "Fabrication d'équipements de levage et de manutention",
  '29.2F': "Fabrication d'équipements aérauliques et frigorifiques industriels",
  '29.2H': "Fabrication d'équipements d'emballage et de conditionnement",
  '29.2J': "Fabrication d'appareils de pesage",
  '29.2K': "Fabrication de machines diverses d'usage général",
  '29.3A': 'Fabrication de tracteurs agricoles',
  '29.3C': 'Réparation de matériel agricole',
  '29.3D': 'Fabrication de matériel agricole',
  '29.4A': 'Fabrication de machines-outils à métaux',
  '29.4B': 'Fabrication de machines-outils à bois',
  '29.4C': 'Fabrication de machines-outils portatives à moteur incorporé',
  '29.4D': 'Fabrication de matériel de soudage',
  '29.4E': "Fabrication d'autres machines-outils",
  '29.5A': 'Fabrication de machines pour la métallurgie',
  '29.5C': "Fabrication de machines pour l'extraction ou la construction",
  '29.5E': "Fabrication de machines pour l'industrie agroalimentaire",
  '29.5G': 'Fabrication de machines pour les industries textiles',
  '29.5J': 'Fabrication de machines pour les industries du papier et du carton',
  '29.5L': "Fabrication de machines d'imprimerie",
  '29.5M':
    'Fabrication de machines pour le travail du caoutchouc ou des plastiques',
  '29.5N': 'Fabrication de moules et modèles',
  '29.5P': "Fabrication d'autres machines spécialisées",
  '29.6A': "Fabrication d'armement",
  '29.6B': "Fabrication d'armes de chasse, de tir et de défense",
  '29.7A': "Fabrication d'appareils électroménagers",
  '29.7C': "Fabrication d'appareils ménagers non électriques",
  '30.0A': 'Fabrication de machines de bureau',
  '30.0C': "Fabrication d'ordinateurs et d'autres équipements informatiques",
  '31.1A':
    'Fabrication de moteurs, génératrices et transformateurs électriques de petite et moyenne puissance',
  '31.1B':
    'Fabrication de moteurs, génératrices et transformateurs électriques de très grande puissance',
  '31.1C': 'Réparation de matériels électriques',
  '31.2A':
    'Fabrication de matériel de distribution et de commande électrique pour basse tension',
  '31.2B':
    'Fabrication de matériel de distribution et de commande électrique pour haute tension',
  '31.3Z': 'Fabrication de fils et câbles isolés',
  '31.4Z': "Fabrication d'accumulateurs et de piles électriques",
  '31.5A': 'Fabrication de lampes',
  '31.5B': "Fabrication d'appareils électriques autonomes de sécurité",
  '31.5C': "Fabrication d'appareils d'éclairage",
  '31.6A': 'Fabrication de matériels électriques pour moteurs et véhicules',
  '31.6C': 'Fabrication de matériel électromagnétique industriel',
  '31.6D': 'Fabrication de matériels électriques n.c.a.',
  '32.1A': 'Fabrication de composants passifs et de condensateurs',
  '32.1B': 'Fabrication de composants électroniques actifs',
  '32.2A': "Fabrication d'équipements d'émission et de transmission hertzienne",
  '32.2B': "Fabrication d'appareils de téléphonie",
  '32.3Z':
    "Fabrication d'appareils de réception, enregistrement ou reproduction du son et de l'image",
  '33.1A': "Fabrication de matériel d'imagerie médicale et de radiologie",
  '33.1B': "Fabrication d'appareils médicochirurgicaux",
  '33.2A': "Fabrication d'équipements d'aide à la navigation",
  '33.2B': "Fabrication d'instrumentation scientifique et technique",
  '33.3Z': "Fabrication d'équipements de contrôle des processus industriels",
  '33.4A': 'Fabrication de lunettes',
  '33.4B': "Fabrication d'instruments d'optique et de matériel photographique",
  '33.5Z': 'Horlogerie',
  '34.1Z': 'Construction de véhicules automobiles',
  '34.2A': 'Fabrication de carrosseries automobiles',
  '34.2B': 'Fabrications de caravanes et véhicules de loisirs',
  '34.3Z': "Fabrication d'équipements automobiles",
  '35.1A': 'Construction de bâtiments de guerre',
  '35.1B': 'Construction de navires civils',
  '35.1C': 'Réparation navale',
  '35.1E': 'Construction de bateaux de plaisance',
  '35.2Z': 'Construction de matériel ferroviaire roulant',
  '35.3A': 'Construction de moteurs pour aéronefs',
  '35.3B': "Construction de cellules d'aéronefs",
  '35.3C': 'Construction de lanceurs et engins spatiaux',
  '35.4A': 'Fabrication de motocycles',
  '35.4C': 'Fabrication de bicyclettes',
  '35.4E': 'Fabrication de véhicules pour invalides',
  '35.5Z': 'Fabrication de matériels de transport n.c.a.',
  '36.1A': 'Fabrication de sièges',
  '36.1C': 'Fabrication de meubles de bureau et de magasin',
  '36.1E': 'Fabrication de meubles de cuisine',
  '36.1G': 'Fabrication de meubles meublants',
  '36.1H': "Fabrication de meubles de jardin et d'extérieur",
  '36.1J': 'Fabrication de meubles n.c.a.',
  '36.1K': "Industries connexes de l'ameublement",
  '36.1M': 'Fabrication de matelas',
  '36.2A': 'Fabrication de monnaies et médailles',
  '36.2C': 'Bijouterie, joaillerie, orfèvrerie',
  '36.3Z': "Fabrication d'instruments de musique",
  '36.4Z': "Fabrication d'articles de sport",
  '36.5Z': 'Fabrication de jeux et jouets',
  '36.6A': 'Bijouterie fantaisie',
  '36.6C': 'Industrie de la brosserie',
  '36.6E': 'Autres activités manufacturières n.c.a.',
  '37.1Z': 'Récupération de matières métalliques recyclables',
  '37.2Z': 'Récupération de matières non métalliques recyclables',
  '40.1Z': "Production et distribution d'électricité",
  '40.2Z': 'Production et distribution de combustibles gazeux',
  '40.3Z': 'Production et distribution de chaleur',
  '41.0Z': "Captage, traitement et distribution d'eau",
  '45.1A': 'Terrassements divers, démolition',
  '45.1B': 'Terrassements en grande masse',
  '45.1D': 'Forages et sondages',
  '45.2A': 'Construction de maisons individuelles',
  '45.2B': 'Construction de bâtiments divers',
  '45.2C': "Construction d'ouvrages d'art",
  '45.2D': 'Travaux souterrains',
  '45.2E': 'Réalisation de réseaux',
  '45.2F': 'Construction de lignes électriques et de télécommunication',
  '45.2J': 'Réalisation de couvertures par éléments',
  '45.2K': "Travaux d'étanchéification",
  '45.2L': 'Travaux de charpente',
  '45.2N': 'Construction de voies ferrées',
  '45.2P': 'Construction de chaussées routières et de sols sportifs',
  '45.2R': 'Travaux maritimes et fluviaux',
  '45.2T': 'Levage, montage',
  '45.2U': 'Autres travaux spécialisés de construction',
  '45.2V': 'Travaux de maçonnerie générale',
  '45.3A': "Travaux d'installation électrique",
  '45.3C': "Travaux d'isolation",
  '45.3E': "Installation d'eau et de gaz",
  '45.3F': "Installation d'équipements thermiques et de climatisation",
  '45.3H': "Autres travaux d'installation",
  '45.4A': 'Plâtrerie',
  '45.4C': 'Menuiserie bois et matières plastiques',
  '45.4D': 'Menuiserie métallique ; serrurerie',
  '45.4F': 'Revêtement des sols et des murs',
  '45.4H': 'Miroiterie de bâtiment, vitrerie',
  '45.4J': 'Peinture',
  '45.4L': 'Agencement de lieux de vente',
  '45.4M': 'Travaux de finition n.c.a.',
  '45.5Z': 'Location avec opérateur de matériel de construction',
  '50.1Z': 'Commerce de véhicules automobiles',
  '50.2Z': 'Entretien et réparation de véhicules automobiles',
  '50.3A': "Commerce de gros d'équipements automobiles",
  '50.3B': "Commerce de détail d'équipements automobiles",
  '50.4Z': 'Commerce et réparation de motocycles',
  '50.5Z': 'Commerce de détail de carburants',
  '51.1A':
    'Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et demi-produits',
  '51.1C':
    'Intermédiaires du commerce combustibles, métaux, minéraux et produits chimiques',
  '51.1E': 'Intermédiaires du commerce en bois et matériaux de construction',
  '51.1G':
    'Intermédiaires du commerce en machines, équipements industriels, navires et avions',
  '51.1J':
    'Intermédiaires du commerce en meubles, articles de ménage et quincaillerie',
  '51.1L':
    'Intermédiaires du commerce en textiles, habillement, chaussures et articles en cuir',
  '51.1N': 'Intermédiaires du commerce en produits alimentaires',
  '51.1P': "Centrales d'achats alimentaires",
  '51.1R': 'Autres intermédiaires spécialisés du commerce',
  '51.1T': 'Intermédiaires non spécialisés du commerce',
  '51.1U': "Centrales d'achats non alimentaires",
  '51.2A': 'Commerce de gros de céréales et aliments pour le bétail',
  '51.2C': 'Commerce de gros de fleurs et plantes',
  '51.2E': "Commerce de gros d'animaux vivants",
  '51.2G': 'Commerce de gros de cuirs et peaux',
  '51.2J': 'Commerce de gros de tabac non manufacturé',
  '51.3A': 'Commerce de gros de fruits et légumes',
  '51.3C': 'Commerce de gros de viandes de boucherie',
  '51.3D': 'Commerce de gros de produits à base de viande',
  '51.3E': 'Commerce de gros de volailles et gibiers',
  '51.3G': 'Commerce de gros de produits laitiers, oeufs, huiles',
  '51.3J': 'Commerce de gros de boissons',
  '51.3L': 'Commerce de gros de tabac',
  '51.3N': 'Commerce de gros de sucre, chocolat et confiserie',
  '51.3Q': 'Commerce de gros de café, thé, cacao et épices',
  '51.3S': 'Commerce de gros de poissons, crustacés et mollusques',
  '51.3T': 'Commerces de gros alimentaires spécialisés divers',
  '51.3V': 'Commerce de gros de produits surgelés',
  '51.3W': 'Commerce de gros alimentaire non spécialisé',
  '51.4A': 'Commerce de gros de textiles',
  '51.4C': "Commerce de gros d'habillement",
  '51.4D': 'Commerce de gros de la chaussure',
  '51.4F': 'Commerce gros appareils électroménagers et radiotélévision',
  '51.4H': 'Commerce de gros de vaisselle et verrerie de ménage',
  '51.4J':
    "Commerce de gros de produits pour l'entretien et l'aménagement de l'habitat",
  '51.4L': 'Commerce de gros de parfumerie et de produits de beauté',
  '51.4N': 'Commerce de gros de produits pharmaceutiques',
  '51.4Q': 'Commerce de gros de papeterie',
  '51.4R': 'Commerce de gros de jouets',
  '51.4S': 'Autres commerces de gros de biens de consommation',
  '51.5A': 'Commerce de gros de combustibles',
  '51.5C': 'Commerce de gros de minerais et métaux',
  '51.5E': 'Commerce de gros de bois et de produits dérivés',
  '51.5F':
    "Commerce de gros de matériaux de construction et d'appareils sanitaires",
  '51.5H': 'Commerce de gros de quincaillerie',
  '51.5J': 'Commerce de gros de fournitures pour plomberie et chauffage',
  '51.5L': 'Commerce de gros de produits chimiques',
  '51.5N': "Commerce de gros d'autres produits intermédiaires",
  '51.5Q': 'Commerce de gros de déchets et débris',
  '51.6A': 'Commerce de gros de machines-outils',
  '51.6C': "Commerce de gros d'équipements pour la construction",
  '51.6E':
    "Commerce de gros de machines pour l'industrie textile et l'habillement",
  '51.6G': 'Commerce de gros de machines de bureau et de matériel informatique',
  '51.6J': 'Commerce de gros de matériel électrique et électronique',
  '51.6K': 'Commerce gros fournitures et équipements industriels divers',
  '51.6L':
    'Commerce de gros de fournitures et équipements divers pour le commerce et les services',
  '51.6N': 'Commerce de gros de matériel agricole',
  '51.7Z': 'Commerce de gros non spécialisé',
  '52.1A': 'Commerce de détail de produits surgelés',
  '52.1B': "Commerce d'alimentation générale",
  '52.1C': 'Supérettes',
  '52.1D': 'Supermarchés',
  '52.1E': 'Magasins populaires',
  '52.1F': 'Hypermarchés',
  '52.1H': 'Grands magasins',
  '52.1J': 'Autres commerces de détail en magasin non spécialisé',
  '52.2A': 'Commerce de détail de fruits et légumes',
  '52.2C': 'Commerce de détail de viandes et produits à base de viande',
  '52.2E': 'Commerce de détail de poissons, crustacés et mollusques',
  '52.2G': 'Commerce de détail de pain, pâtisserie et confiserie',
  '52.2J': 'Commerce de détail de boissons',
  '52.2L': 'Commerce de détail de tabac',
  '52.2N': 'Commerce de détail de produits laitiers',
  '52.2P': 'Commerces de détail alimentaires spécialisés divers',
  '52.3A': 'Commerce de détail de produits pharmaceutiques',
  '52.3C': "Commerce de détail d'articles médicaux et orthopédiques",
  '52.3E': 'Commerce de détail de parfumerie et de produits de beauté',
  '52.4A': 'Commerce de détail de textiles',
  '52.4C': "Commerce de détail d'habillement",
  '52.4E': 'Commerce de détail de la chaussure',
  '52.4F': "Commerce de détail de maroquinerie et d'articles de voyage",
  '52.4H': 'Commerce de détail de meubles',
  '52.4J': "Commerce de détail d'équipement du foyer",
  '52.4L':
    "Commerce de détail d'appareils électroménagers et de radio télévision",
  '52.4N': 'Commerce de détail de quincaillerie',
  '52.4P': 'Commerce de détail de bricolage',
  '52.4R': 'Commerce de détail de livres, journaux et papeterie',
  '52.4T': "Commerce de détail d'optique et de photographie",
  '52.4U': 'Commerce de détail de revêtements de sols et de murs',
  '52.4V': "Commerce de détail d'horlogerie et de bijouterie",
  '52.4W': "Commerce de détail d'articles de sport et de loisir",
  '52.4X': 'Commerce de détail de fleurs',
  '52.4Y': 'Commerce de détail de charbons et combustibles',
  '52.4Z': 'Commerces de détail divers en magasin spécialisé',
  '52.5Z': "Commerce de détail de biens d'occasion",
  '52.6A': 'Vente par correspondance sur catalogue général',
  '52.6B': 'Vente par correspondance spécialisée',
  '52.6D': 'Commerce de détail alimentaire sur éventaires et marchés',
  '52.6E': 'Commerce de détail non alimentaire sur éventaires et marchés',
  '52.6G': 'Vente à domicile',
  '52.6H': 'Vente par automate',
  '52.7A': "Réparation de chaussures et d'articles en cuir",
  '52.7C': 'Réparation de matériel électronique grand public',
  '52.7D': "Réparation d'appareils électroménagers",
  '52.7F': 'Réparation de montres, horloges et bijoux',
  '52.7H': "Réparation d'articles personnels et domestiques n.c.a.",
  '55.1A': 'Hôtels avec restaurant',
  '55.1C': 'Hôtels de tourisme sans restaurant',
  '55.1D': 'Hôtels de préfecture',
  '55.2A': 'Auberges de jeunesse et refuges',
  '55.2C': 'Exploitation de terrains de camping',
  '55.2E': 'Autre hébergement touristique',
  '55.2F': 'Hébergement collectif non touristique',
  '55.3A': 'Restauration de type traditionnel',
  '55.3B': 'Restauration de type rapide',
  '55.4A': 'Cafés tabacs',
  '55.4B': 'Débits de boissons',
  '55.5A': "Cantines et restaurants d'entreprises",
  '55.5C': 'Restauration collective sous contrat',
  '55.5D': 'Traiteurs, organisation de réceptions',
  '60.1Z': 'Transports ferroviaires',
  '60.2A': 'Transports urbains de voyageurs',
  '60.2B': 'Transports routiers réguliers de voyageurs',
  '60.2C': 'Téléphériques, remontées mécaniques',
  '60.2E': 'Transport de voyageurs par taxis',
  '60.2G': 'Autres transports routiers de voyageurs',
  '60.2L': 'Transports routiers de marchandises de proximité',
  '60.2M': 'Transports routiers de marchandises interurbains',
  '60.2N': 'Déménagement',
  '60.2P': 'Location de camions avec conducteur',
  '60.3Z': 'Transports par conduites',
  '61.1A': 'Transports maritimes',
  '61.1B': 'Transports côtiers',
  '61.2Z': 'Transports fluviaux',
  '62.1Z': 'Transports aériens réguliers',
  '62.2Z': 'Transports aériens non réguliers',
  '62.3Z': 'Transports spatiaux',
  '63.1A': 'Manutention portuaire',
  '63.1B': 'Manutention non portuaire',
  '63.1D': 'Entreposage frigorifique',
  '63.1E': 'Entreposage non frigorifique',
  '63.2A': "Gestion d'infrastructures de transports terrestres",
  '63.2C': 'Services portuaires, maritimes et fluviaux',
  '63.2E': 'Services aéroportuaires',
  '63.3Z': 'Agences de voyage',
  '63.4A': 'Messagerie, fret express',
  '63.4B': 'Affrètement',
  '63.4C': 'Organisation des transports internationaux',
  '64.1A': 'Postes nationales',
  '64.1C': 'Autres activités de courrier',
  '64.2A': 'Télécommunications nationales',
  '64.2B': 'Autres activités de télécommunications',
  '65.1A': 'Banque centrale',
  '65.1C': 'Banques',
  '65.1D': 'Banques mutualistes',
  '65.1E': "Caisses d'épargne",
  '65.1F': 'Intermédiations monétaires n.c.a.',
  '65.2A': 'Crédit-bail',
  '65.2C': 'Distribution de crédit',
  '65.2E': 'Organismes de placement en valeurs mobilières',
  '65.2F': 'Intermédiations financières diverses',
  '66.0A': 'Assurance-vie et capitalisation',
  '66.0C': 'Caisses de retraite',
  '66.0E': 'Assurance dommages',
  '66.0F': 'Réassurance',
  '66.0G': 'Assurance relevant du code de la mutualité',
  '67.1A': 'Administration de marchés financiers',
  '67.1C': 'Gestion de portefeuilles',
  '67.1E': 'Autres auxiliaires financiers',
  '67.2Z': "Auxiliaires d'assurance",
  '70.1A': 'Promotion immobilière de logements',
  '70.1B': 'Promotion immobilière de bureaux',
  '70.1C': "Promotion immobilière d'infrastructures",
  '70.1D': 'Supports juridiques de programme',
  '70.1F': 'Marchands de biens immobiliers',
  '70.2A': 'Location de logements',
  '70.2B': 'Location de terrains',
  '70.2C': "Location d'autres biens immobiliers",
  '70.3A': 'Agences immobilières',
  '70.3C': "Administration d'immeubles résidentiels",
  '70.3D': "Administration d'autres biens immobiliers",
  '70.3E': 'Supports juridiques de gestion de patrimoine',
  '71.1Z': 'Location de véhicules automobiles',
  '71.2A': "Location d'autres matériels de transport terrestre",
  '71.2C': 'Location de matériels de transport par eau',
  '71.2E': "Location d'appareils de transport aérien",
  '71.3A': 'Location de matériel agricole',
  '71.3C': 'Location de machines et équipements pour la construction',
  '71.3E': 'Location de machines de bureau et de matériel informatique',
  '71.3G': 'Location de machines et équipements divers',
  '71.4A': 'Location de linge',
  '71.4B': "Location d'autres biens personnels et domestiques",
  '72.1Z': 'Conseil en systèmes informatiques',
  '72.2Z': 'Réalisation de logiciels',
  '72.3Z': 'Traitement de données',
  '72.4Z': 'Activités de banques de données',
  '72.5Z':
    'Entretien et réparation de machines de bureau et de matériel informatique',
  '72.6Z':
    "Autres activités rattachées à l'informatique (L'utilisation de cette classe est différée jusqu'à nouvel avis)",
  '73.1Z': 'Recherche-développement en sciences physiques et naturelles',
  '73.2Z': 'Recherche-développement en sciences humaines et sociales',
  '74.1A': 'Activités juridiques',
  '74.1C': 'Activités comptables',
  '74.1E': 'Etudes de marché et sondages',
  '74.1G': 'Conseil pour les affaires et la gestion',
  '74.1J': "Administration d'entreprises",
  '74.2A': "Activités d'architecture",
  '74.2B': 'Métreurs, géomètres',
  '74.2C': 'Ingénierie, études techniques',
  '74.3A': 'Contrôle technique automobile',
  '74.3B': 'Analyses, essais et inspections techniques',
  '74.4A': 'Gestion de supports de publicité',
  '74.4B': 'Agences, conseil en publicité',
  '74.5A': 'Sélection et mise à disposition de personnel',
  '74.5B': 'Travail temporaire',
  '74.6Z': 'Enquêtes et sécurité',
  '74.7Z': 'Activités de nettoyage',
  '74.8A': 'Studios et autres activités photographiques',
  '74.8B': 'Laboratoires techniques de développement et de tirage',
  '74.8D': 'Conditionnement à façon',
  '74.8F': 'Secrétariat et traduction',
  '74.8G': 'Routage',
  '74.8J': 'Organisation de foires et salons',
  '74.8K': 'Services annexes à la production',
  '75.1A': 'Administration publique générale',
  '75.1C': 'Tutelle des activités sociales',
  '75.1E': 'Tutelle des activités économiques',
  '75.1G': 'Activités de soutien aux administrations',
  '75.2A': 'Affaires étrangères',
  '75.2C': 'Défense',
  '75.2E': 'Justice',
  '75.2G': 'Police',
  '75.2J': 'Protection civile',
  '75.3A': 'Activités générales de sécurité sociale',
  '75.3B': 'Gestion des retraites complémentaires',
  '75.3C': 'Distribution sociale de revenus',
  '80.1Z': 'Enseignement primaire',
  '80.2A': 'Enseignement secondaire général',
  '80.2C': 'Enseignement secondaire technique ou professionnel',
  '80.3Z': 'Enseignement supérieur',
  '80.4A': 'Ecoles de conduite',
  '80.4C': 'Formation des adultes et formation continue',
  '80.4D': 'Autres enseignements',
  '85.1A': 'Activités hospitalières',
  '85.1C': 'Pratique médicale',
  '85.1E': 'Pratique dentaire',
  '85.1G': 'Activités des auxiliaires médicaux',
  '85.1H': "Soins hors d'un cadre réglementé",
  '85.1J': 'Ambulances',
  '85.1K': "Laboratoires d'analyses médicales",
  '85.1L': "Centres de collecte et banques d'organes",
  '85.2Z': 'Activités vétérinaires',
  '85.3A': 'Accueil des enfants handicapés',
  '85.3B': 'Accueil des enfants en difficulté',
  '85.3C': 'Accueil des adultes handicapés',
  '85.3D': 'Accueil des personnes âgées',
  '85.3E': 'Autres hébergements sociaux',
  '85.3G': "Crèches et garderies d'enfants",
  '85.3H': 'Aide par le travail, ateliers protégés',
  '85.3J': 'Aide à domicile',
  '85.3K': "Autres formes d'action sociale",
  '90.0A': 'Epuration des eaux usées',
  '90.0B': 'Enlèvement et traitement des ordures ménagères',
  '90.0C': 'Elimination et traitement des autres déchets',
  '91.1A': 'Organisations patronales et consulaires',
  '91.1C': 'Organisations professionnelles',
  '91.2Z': 'Syndicats de salariés',
  '91.3A': 'Organisations religieuses',
  '91.3C': 'Organisations politiques',
  '91.3E': 'Organisations associatives n.c.a.',
  '92.1A': 'Production de films pour la télévision',
  '92.1B': 'Production de films institutionnels et publicitaires',
  '92.1C': 'Production de films pour le cinéma',
  '92.1D': 'Prestations techniques pour le cinéma et la télévision',
  '92.1F': 'Distribution de films cinématographiques',
  '92.1G': 'Edition et distribution vidéo',
  '92.1J': 'Projection de films cinématographiques',
  '92.2A': 'Activités de radio',
  '92.2B': 'Production de programmes de télévision',
  '92.2C': 'Diffusion de programmes de télévision',
  '92.3A': 'Activités artistiques',
  '92.3B': 'Services annexes aux spectacles',
  '92.3D': 'Gestion de salles de spectacles',
  '92.3F': "Manèges forains et parcs d'attractions",
  '92.3H': 'Bals et discothèques',
  '92.3J': 'Autres spectacles',
  '92.4Z': 'Agences de presse',
  '92.5A': 'Gestion des bibliothèques',
  '92.5C': 'Gestion du patrimoine culturel',
  '92.5E': 'Gestion du patrimoine naturel',
  '92.6A': "Gestion d'installations sportives",
  '92.6C': 'Autres activités sportives',
  '92.7A': "Jeux de hasard et d'argent",
  '92.7C': 'Autres activités récréatives',
  '93.0A': 'Blanchisserie - teinturerie de gros',
  '93.0B': 'Blanchisserie - teinturerie de détail',
  '93.0D': 'Coiffure',
  '93.0E': 'Soins de beauté',
  '93.0G': 'Soins aux défunts',
  '93.0H': 'Pompes funèbres',
  '93.0K': 'Activités thermales et de thalassothérapie',
  '93.0L': 'Autres soins corporels',
  '93.0N': 'Autres services personnels',
  '95.0Z': 'Services domestiques',
  '99.0Z': 'Activités extra-territoriales',
};
